<template>
    <div>
        <CRow class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs" />
            </v-col>
            <v-col
                sm="8"
                class="d-flex justify-end align-center flex-column flex-md-row"
            >
                <CButton
                    @click="
                        clearCurrentItem()
                        showEntityModal = true
                    "
                    class="add-product-modal"
                    color="info"
                    ><i class="la la-plus"></i>
                    Добавить рассылку
                </CButton>
            </v-col>
        </CRow>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="mailings"
                    :resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :detail="detailTemplate"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                    @sortchange="sortChange"
                >
                    <template v-slot:detail="{ props: { dataItem } }">
                        <div class="detail-wrapper dealer-detail">
                            <v-tabs v-model="tab">
                                <v-tab key="text" href="#text">Текст</v-tab>
                                <v-tab key="recipients" href="#recipients"
                                    >Получатели</v-tab
                                >
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                    key="text"
                                    id="text"
                                    md-label="Текст"
                                >
                                    <div
                                        class="py-5 px-5"
                                        v-html="dataItem.text"
                                    />
                                </v-tab-item>
                                <v-tab-item
                                    key="recipients"
                                    id="recipients"
                                    md-label="Получатели"
                                >
                                    <div
                                        class="py-5 px-5"
                                        v-html="dataItem.recipients_names"
                                    />
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </template>
                </Grid>
            </intl>
        </localization>
        <mailing-create-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            @click:outside="showEntityModal = false"
            @close="showEntityModal = false"
            @stored="entityStored($event)"
            :dealers-list="dealersList"
            :subdivision-users-list="subdivisionUsersList"
        />
    </div>
</template>

<script>
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import MailingCreateModal from './modals/MailingCreateModal'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'Mailings',
        components: {
            MailingCreateModal,
            Grid,
            Breadcrumbs,
            localization: LocalizationProvider,
            intl: IntlProvider,
        },
        mixins: [TableMixin, ResizableTableMixin],
        mounted() {
            this.getData()
            this.fetchDealers()
            this.fetchSubdivisionUsers()
        },
        data() {
            return {
                tab: '',
                skip: 0,
                take: 10,
                pageSize: 10,
                total: 7,
                detailTemplate: 'detail',
                gridPageable: { pageSizes: true },
                expandedItems: [],
                currentItem: null,
                dealersList: [],
                subdivisionUsersList: [],
                columns: [
                    {
                        field: 'date',
                        title: 'Дата договора',
                        filter: 'date',
                        format: '{0:d}',
                        type: 'date',
                    },
                    {
                        field: 'subject',
                        title: 'Тема',
                    },
                ],
            }
        },
        methods: {
            fetchData: api.mailings.getMailings,
            async fetchDealers() {
                this.dealersList = await api.dealers.dealersActive()
            },
            async fetchSubdivisionUsers() {
                this.subdivisionUsersList =
                    await api.structural_subdivisions.subdivisionUsersActive()
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
        },
    }
</script>

<style scoped></style>
