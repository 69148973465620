<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog class="dialog" :value="showDialog" :persistent="false" width="60vw" @input="closeDialog">
      <v-card>
        <v-card-title class="headline">
          <span><i class="flaticon-paper-plane mr-2"></i>Добавление рассылки</span>
        </v-card-title>
        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="subject" rules="required" v-slot="{invalid, validated}">
                <v-text-field :error="invalid && validated" label="Тема *" hide-details="auto" v-model="subject"/>
              </ValidationProvider>
              <h2 class="h5 my-5">Текст сообщения</h2>
              <ckeditor editor-url="/js/ckeditor/ckeditor.js" v-model="text" :config="editorConfig"/>
              <h3 class="h5 mt-5">Получатели</h3>
              <v-tabs v-model="tab">
                <v-tab key="dealers" href="#dealers">Дилеры</v-tab>
                <v-tab key="users" href="#users">Пользователи структурного подразделения</v-tab>
                <v-tabs-items v-model="tab">
                  <v-tab-item key="dealers" id="dealers" md-label="Дилеры">
                    <div class="my-4 d-flex flex-column flex-sm-row justify-content-between">
                      <v-btn class="mb-3 mb-sm-0" @click="checkAllCheckboxes('dealer')" color="primary">Выбрать всех</v-btn>
                      <v-btn @click="uncheckAllCheckboxes('dealer')" color="warning">Удалить всех</v-btn>
                    </div>
                    <v-text-field
                        hide-details="auto"
                        outlined
                        :value="searchDealers"
                        @input="setSearch('dealer', $event)"
                    ></v-text-field>
                    <div class="mt-3 d-flex flex-column dealers-list">
                      <div class="d-flex flex-row dealers-list__item" v-for="(dealer, key) in filteredDealerList"
                           :key="dealer.id">
                        <div class="d-flex dealer-checkbox-column">
                          <v-simple-checkbox :value="selectedDealers[dealer.id]" @input="changeCheckbox('dealer', dealer.id, $event)" class="dealer-checkbox"></v-simple-checkbox>
                        </div>
                        <div class="d-flex flex-grow-1 dealer-name-column">
                          {{ dealer.name }}
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item key="users" id="users" md-label="Пользователи структурного подразделения">
                    <div class="my-4 d-flex flex-column flex-sm-row justify-content-between">
                      <v-btn class="mb-3 mb-sm-0" @click="checkAllCheckboxes('subdivision_user')" color="primary">Выбрать всех</v-btn>
                      <v-btn @click="uncheckAllCheckboxes('subdivision_user')" color="warning">Удалить всех</v-btn>
                    </div>
                    <v-text-field
                        hide-details="auto"
                        outlined
                        :value="searchSubdivisionUsers"
                        @input="setSearch('subdivision_users', $event)"
                    ></v-text-field>
                    <div class="mt-3 d-flex flex-column subdivision-users-list">
                      <div class="d-flex flex-row subdivision-users-list__item" v-for="(subdivisionUser, key) in filteredSubdivisionUserList" :key="subdivisionUser.id">
                        <div class="d-flex subdivision-user-checkbox-column">
                          <v-simple-checkbox :value="selectedSubdivisionUsers[subdivisionUser.id]" @input="changeCheckbox('subdivision_user', subdivisionUser.id, $event)" class="subdivision-user-checkbox"></v-simple-checkbox>
                        </div>
                        <div class="d-flex flex-grow-1 subdvision-user-name-column">
                          {{ subdivisionUser.name }}
                        </div>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-layout>
          </form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid || isDisable" @click="handleSubmit(submit)" color="success">
            <i class="mr-2 fa fa-save"></i> <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import debounce from "lodash.debounce";
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "MailingCreateModal",
  props: [
    'dealersList',
    'subdivisionUsersList',
  ],
  components: {
    ValidationObserver,
    ValidationProvider,
    'ckeditor': CKEditor.component
  },
  mixins: [
    EntityModalMixin
  ],
  data() {
    let state = {
      tab: '',
      id: null,
      subject: '',
      text: '',
      dealers: [],
      subdivisionUsers: [],
      editorConfig: {
        height: '34vh'
      },
      isDisable: false,
      searchDealers: '',
      selectedDealers: {},
      searchSubdivisionUsers: '',
      selectedSubdivisionUsers: {}
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  computed: {
    data() {
      return {
        subject: this.subject,
        text: this.text,
        dealers: Object.keys(this.selectedDealers),
        subdivisionUsers: Object.keys(this.selectedSubdivisionUsers),
      }
    },
    filteredDealerList() {
      if (this.searchDealers) {
        const reg = new RegExp(this.searchDealers,"i");

        return this.dealersList.filter((dealer) => {
          return dealer.name.match(reg)
        })
      }

      return this.dealersList;
    },
    filteredSubdivisionUserList() {
      if (this.searchSubdivisionUsers) {
        const reg = new RegExp(this.searchSubdivisionUsers,"i");

        return this.subdivisionUsersList.filter((user) => {
          return user.name.match(reg)
        })
      }

      return this.subdivisionUsersList;
    },
    allDealerIds() {
      const dealerIds = {};
      const list = this.filteredDealerList;

      list.forEach(({id}) => {
        dealerIds[id] = true;
      });

      return dealerIds;
    },
    allSubdivisionUserIds() {
      const subdivisionUserIds = {};
      const list = this.filteredSubdivisionUserList;

      list.forEach(({id}) => {
        subdivisionUserIds[id] = true;
      });

      return subdivisionUserIds;
    },
  },
  methods: {
    disabledBtn(status) {
      this.isDisable = status;
    },
    createEntity: api.mailings.createMailing,
    setSearch: debounce(async function (type, search) {
      const searchName = type === 'dealer' ? 'searchDealers' : 'searchSubdivisionUsers';
      this.$data[searchName] = search;
    }, 400),
    checkAllCheckboxes(type) {
      const selectedListName = type === 'dealer' ? 'selectedDealers' : 'selectedSubdivisionUsers';
      this.$data[selectedListName] = type === 'dealer' ? this.allDealerIds : this.allSubdivisionUserIds;
    },
    uncheckAllCheckboxes(type) {
      const selectedListName = type === 'dealer' ? 'selectedDealers' : 'selectedSubdivisionUsers';
      this.$data[selectedListName] = {};
    },
    changeCheckbox(type, userId, value) {
      const selectedCheckboxes = {...(type === 'dealer' ? this.selectedDealers : this.selectedSubdivisionUsers)};

      if (value) {
        selectedCheckboxes[userId] = value
      } else {
        delete selectedCheckboxes[userId];
      }

      if (type === 'dealer') {
        this.selectedDealers = selectedCheckboxes;
      } else {
        this.selectedSubdivisionUsers = selectedCheckboxes;
      }
    },
  }
}
</script>

<style lang="scss">
.v-dialog {
  @media (max-width: 992px) {
    width: 90vw !important;
  }

  .v-tabs {
    .v-slide-group__prev--disabled {
      display: none !important;
    }

    .v-slide-group__content {
      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }


    @media (max-width: 992px) {
      .v-tabs-slider-wrapper {
        display: none !important;
      }
    }
  }
}

.combox {
  .v-select__selection--comma {
    display: none !important;
  }
}
</style>
